.pagenum{
    padding-bottom: 20px;
    .btn{
        margin-top: 20px;
        padding-left: 15px;
        padding-right: 15px;
        &:not(:first-child){
            margin-left: $grid-gutter/2;
        }
    }
}