%header-item{
    @extend %flex;
    @extend .row;
    @extend .center;
    @extend .no-wrap;
    padding-left: 2rem;
    padding-right: 2rem;
    color: $color-prime;
    .fas{
        font-size: 1.5rem;
        // padding-right: 0.5rem;
    }
    
}
%header-link{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid transparent;
    
    @include hover-focus-active{
        color: $color-second;
        text-decoration: none;
        
        border-color: $color-clouds;
        background-color: rgba(#333, 0.03);

        border-radius: 3px;
        cursor: pointer;

        .header__callback{
            &__title{
                text-decoration: none;
            }
            &__title, &__desc, .icon{
                color: $color-prime;
            }
        }
    }
    @include media-breakpoint-up(md){

    }
}

.header{
    // height: 78px;
    background-color: #fff;
    color: $color-prime;
    border-bottom: 2px solid $color-border-5;

    &.with-banner{
        margin-bottom: 0rem;
        border-bottom: none;
    }

    @include media-breakpoint-up(md){
        // height: 102px;
    }

    &__user,
    &__search-icon,
    &__search-icon-close,
    &__mob-menu-btn,
    &__callback{
        @extend %header-item;
        @extend %header-link;
    }

    &__logo{
        padding-top: 1.1rem;
        padding-bottom: 1.25rem;
        img{            
            width: auto;
            height: 36px !important;
            @include media-breakpoint-up(md){
                height: 42px !important;
            }
            @include media-breakpoint-up(lg){
                height: 48px !important;
            }
            @include media-breakpoint-up(xl){
                height: 56px !important;
            }
        }
        a{
            font-family: $ff-h-bold;
            color: $color-prime;
            text-decoration: none;
        }
    }
    &__callback{
        flex-direction: column;
        @extend .j-center;

        text-decoration: none;

        &__title{
            font-size: 18px;
            color: $color-second;
            text-decoration: underline;
        }
        &__desc{
            font-size: 16px;
            font-family: $ff-italic;
        }
        .icon{
            color: $color-second;
        }

    }
    &__contacts{
        padding-bottom: 7px;
        @include media-breakpoint-up(xl){
            padding-left: 3rem;
        }
    }
    &__phone{
        color: $color-prime;
        font-family: $ff-h-medium;
        font-size: 1.4rem;
        line-height: 1em;
        text-decoration: none;

        @include hover-focus-active {
            color: $color-gray-3;
        }
    }
    &__email{
        padding-bottom: 7px;
    }

    &__search-form{
        padding-left: 90px;
    }
    &__search-form,
    &__search-icon-close {
        visibility: hidden;
        opacity: 0;
        display: none;
    }
    &__menu-trigger{
        &__title{
            padding-right: 1rem;

            font-family: $ff-h-bold;
            font-size: 18px;
        }
        a{
            @extend %header-item;
            @extend %header-link;
            text-decoration: none;

            
            // @include media-breakpoint-up(sm){ width: 70px;}
            // @include media-breakpoint-up(lg){ width: 80px;}

            .fa-times{ display: none }
            .fa-bars{ display: block }
            &:active, &.active{
                .fa-times{ display: block }
                .fa-bars{ display: none }
            }
        }
    }
}

.header-mobile{
    height: 60px;
    background-color: #fff;

    color: #fff;
    font-size: 14px;
    border-bottom: 8px solid $color-second;
}