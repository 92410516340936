%pseudo{
    position: absolute;
    left: 0rem;

    font-weight: 900;
    font-size: 2rem;
    line-height: 1.2rem;

    text-align: center;

    transition: all .3;
}

.footer{
    padding-top: 3rem;
    // padding-bottom: 2.5rem;

    color: $color-clouds;
    background-color: $color-ad;
    font-size: 16px;

    &_row_2{
        padding: 1rem 0;
        background-color: $color-ad-1;
    }
    a{
        color: $color-clouds;
    }
    &__item{
        padding-top: 1rem;
        padding-bottom: 1rem;
        @include media-breakpoint-up(lg){
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }
    &__company{
        padding-bottom: 1rem;

        &__name{
            font-family: $ff-h-bold;
            font-size: 1.5rem;
            padding-right: 1.5em;
            margin-bottom: 1rem;
            @include media-breakpoint-up(lg){
                margin-bottom: 0rem;
            }
        }

        &__link{
            padding-right: 1.5rem;

            font-family: $ff-h-bold;
            color: transparentize($color-clouds, 0.5)!important;

            @include hover-focus-active{
                color: $color-clouds!important;
            }
            margin-top: 0.5rem;
            @include media-breakpoint-up(lg){
                margin-top: 0rem;
            }

        }
        &>span{
            flex-direction: column;

            @include media-breakpoint-up(lg){
                flex-direction: row;

            }
        }
    }
    &__address{
        font-family: $ff-italic;
        padding-bottom: 1rem;
    }
    &__phone{
        padding-top: 0.5rem;

        font-family: $ff-h-medium;
        font-size: 1.5rem;
    }
    &__ruls{
        padding-bottom: 1.5rem;
        // padding-left: 3rem;
        line-height: 1.4em;
        @include media-breakpoint-up(lg){
            padding-left: 3rem;
        }
        // &:before{
        //     @extend %pseudo;
        //     font-family: "Font Awesome 6 Free";
        //     top: 4px;
        //     content: "\f573";
        //     line-height: 2rem;
        // }
    }
    &__youtube{
        padding-left: 3rem;
        min-height: 2rem;
        &:before{
            @extend %pseudo;
            font-family: "Font Awesome 5 Brands";
            content: "\f167";
        }
    }
    &__inst{
        padding-left: 3rem;
        min-height: 2rem;
        &:before{
            @extend %pseudo;
            font-family: "Font Awesome 5 Brands";
            content: "\f16d";
        }
    }
}