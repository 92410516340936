%menu-link-paddings{
    padding: 0.9rem 1rem;
    @include media-breakpoint-up(xxl){
        padding: 1.2rem 2rem;
    }
}

%menu-link-style{
    font-family: $ff-h-medium;
    color: $color-prime;
    text-decoration: none;
}
%menu-link-hover-style{
    color: $color-second;
    text-decoration: none;
    background-color: rgba(#333, 0.03);
}

.header__nav{
    @extend %hover-effect-shadow;

    background: #F4F6F6;
    // backdrop-filter: blur(10px);

    border-radius: 5px;
    border: 1px solid #EBEBEB;

    // box-shadow: 0 0 30px rgba($color: #3f3f3f, $alpha: 0.1);
}

.menu{
    $root: &;
    margin-bottom: 0;

    &__link{
        @extend %flex;
        flex-direction: row;
        flex-wrap: nowrap;
        @extend .center;
        @extend .j-center;

        @extend %menu-link-paddings;
        @extend %menu-link-style;

        text-align: center;
        line-height: 1.1rem;

        word-wrap: break-word;
        word-break: keep-all;
        overflow-wrap: break-word;

        .fas{
            margin-bottom: 0.5px;
        }

        &.active {
            color: $color-second;
            text-decoration: none;
            // text-shadow: 0px 0px 4px #00000033;
        }
        
    }
    &__item{
        margin-bottom: 0;
        .drop-menu {
            @extend .popup_hide;
        };
        @include hover-focus-active {
            #{$root}__link{
                @extend %menu-link-hover-style;
            }
            .drop-menu{
                @extend .popup_show;
            }
        }
        &.active{

        }
    }
}


.drop-menu{
    top: 90px;
    width: 230px;
    padding-top: 12px;

    background-color: $color-prime;

    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 30px;
        margin-right: 5px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #fff;
    }
    
    &__link{
        @extend %menu-link-paddings;
        font-family: $ff-h-medium;
        text-decoration: underline;

        background-color: #fff;
        
        color: $color-gray-2;
        // color: #333;

        @include hover-focus-active{
            background-color: $color-gray-6;
            color: $color-gray-2;
            text-decoration: none;
            // background-color: $color-gray-22;
            // color: #333;
        }
        &.active{
            color: $color-second;
        }
    }
}


// Side bar
.m-menu{
    $root: &;
    // top: 61px;
    // right: 0;
    // margin-top: 1rem;
    font-size: 16px;

    color: $color-clouds;
    padding-bottom: 5rem;

    .fas{
        color: $color-second;
    }
    a{
        color: $color-clouds;
    }
    &__auth{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    &__cover{
        position: relative;
        z-index: $menu-z-index;
    }
    &__title{
        font-family: $ff-h-medium;
        @include media-breakpoint-up(lg){
            font-size: 16px;
        }
    }
    &__logo{
        height: 70px;
        img{
            height: 32px;
        }
        padding-left: 1.5em;
    }
    &__info {
        margin-top: 1rem;
        padding-top: 1.25em;
        padding-bottom: 1.25em;
        padding-left: 1.5em;
        
        color: $color-clouds;
        background-color: rgba($color: #000000, $alpha: 0.15);

        box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.15) inset;
        border-bottom: 1px solid rgba(256,256,256, 0.1);

        .header__contacts {
            padding-left: 1.125rem;
        }
    }
    &__phone{
        padding-top: .375em;
        padding-bottom: .375em;

        font-family: $ff-h-normal;
        font-size: 24px;
        color: $color-clouds;

        text-decoration: none;
    }
    &__email-link{
        font-size: 16px;
        color: $color-clouds;
    }
    &__draw{
        padding-top: .5em;
        padding-bottom: .75em;
        background-color: #fff;
    }
    &__capture{
        padding-left: 2rem;
        padding-top: 2.5rem;
        padding-bottom: 1.5rem;

        font-family: $ff-h-bold;
        font-size: 20px;
        color: $color-clouds;

        .fas{
            padding-right: 0.5rem;
        }
    }
    &__list{
        border-top: 2px solid $color-gray-2;
    }
    &__item{
        margin-bottom: 0;

        &.active{
            .m-menu__sub-link{
                border-left-color: $color-second;
            }
        }
    }
    &__link{
        // background-color: #fff;
        padding-left: 1.25rem;
        padding-top: .625em;
        padding-bottom: 0.625em;

        font-family: $ff-h-medium;
        font-size: 16px;
        line-height: 1.2rem;
        color: #cad5d8;
        text-align: left;

        text-decoration: underline;

        cursor: pointer;

        @extend .center;
        @extend .j-start;

        border-left: 4px solid transparent;

        @include hover-focus-active{
            color: $color-clouds!important;
            text-decoration: none;
            background-color: rgba(255,255,255,0.05);
        }
        &.active{
            color: $color-second;
            border-left: 4px solid $color-second;
            font-size: 1.125rem;
            text-decoration: none;
            
        }
    }
    &__submenu{
        margin-bottom: 0;
        transition: none!important;
    }
    &__sub-link{
        padding-left: 1.7rem;
        font-family: $ff-h-normal;
        border-left: 4px solid transparent;

        &.active{
            font-size: 1rem;
            color: $color-second;
            border-left-color: $color-second;
        }
    }
    .last{
        margin-bottom: 1.5rem;
    }
    &__social-icon-wrapper{
        padding-left: 0.3rem;
        padding-right: 0.3rem;
    }
    &__social-icon{
        padding: .3em .4em;
        font-size: 1.4em;
        color: $color-clouds;
        border-radius: 5px;
        @include hover-focus-active{
            background-color: $color-gray-2;
        }
        @include media-breakpoint-up (lg){
            font-size: 1.6em;
        }
    }
}

