a{
    color: $color-second;
    font-family: $ff-h-normal;
    text-decoration: underline;
    background-color: transparent; // Remove the gray background on active links in IE 10.
    -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
    @include hover {
        color: $color-gray-2;
        text-decoration: none;
    }
    &.color-second{
        color: $color-second;
    }
    &.base-color{
        color: $font-color-base;
    }
}
p{
    a{
        color: $color-second;
    }
}
.span-link{
    text-decoration: none;
    span {text-decoration: underline;}
    @include hover-focus {
        text-decoration: none;
        span {text-decoration: none;}
    }
}

.phone-link{
    color: inherit;
    text-decoration: none;
}
.anchor{
    .fas{
        font-size: 0.75em;
        color: $color-second;
    }
}