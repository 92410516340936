$fontPath: "../assets/fonts/";


@font-face {
    font-family: 'OpenSans';
    src: url($fontPath+'Open_Sans/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansItalic';
    src: url($fontPath+'Open_Sans/OpenSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansSemiBold';
    src: url($fontPath+'Open_Sans/OpenSans-Semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansSemiBoldItalic';
    src: url($fontPath+'Open_Sans/OpenSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansBold';
    src: url($fontPath+'Open_Sans/OpenSans-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansBoldItalic';
    src: url($fontPath+'Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// Roboto

@font-face {
	font-family: 'Roboto';
	src: url($fontPath+'Roboto/Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'RobotoItalic';
	src: url($fontPath+'Roboto/Roboto-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'RobotoBold';
	src: url($fontPath+'Roboto/Roboto-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'RobotoBoldItalic';
	src: url($fontPath+'Roboto/Roboto-BoldItalic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'RobotoLight';
	src: url($fontPath+'Roboto/Roboto-Light.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'RobotoLightItalic';
	src: url($fontPath+'Roboto/Roboto-LightItalic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'RobotoMedium';
	src: url($fontPath+'Roboto/Roboto-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

//PT Mono

@font-face {
	font-family: 'PTMono';
	src: url($fontPath+'PT_Mono/pt_mono.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
