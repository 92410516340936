.mr-auto{
    margin-right: auto !important;
}
.ml-auto{
    margin-left: auto !important;
}

.color-gray{
    color: $color-gray-2
}
.color-telegram{
    color: #37AEE2
}
.color-whatsapp{
    color: #189D0E
}

.img{
	flex-direction: row;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	img{
		display: flex;
        object-fit: contain;

		// width: 100%;
        max-width: 100%;
        
        height: auto;
        max-height: auto;
        // object-position: left bottom;
    }
    &_h{
        img{
            height: 100%;
            max-height: 100%;
            width: auto;
        }
    }
}

.bg-img, .bg_img{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.fullscreen-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
}

.fullscreen-bg__video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.show{
    opacity: 1;
    visibility: visible;
    position: relative;
    // display: initial;
}
.hide{
    position: absolute;
    opacity: 0;
    visibility: hidden;
    // display: none;
}

.block{
    padding-bottom: $grid-gutter/2;
}

// ----
// Flex
// ----
.no-wrap { flex-wrap: nowrap; }
.wrap { flex-wrap: wrap; }

.flex_ccc,
.flex-ccc,
.f-ccc {
    @include align(center, center, center);
}

.grow-0{ flex-grow: 0; }
.grow-1{ flex-grow: 1; }
.grow-2{ flex-grow: 2; }
.grow-3{ flex-grow: 3; }
.grow-4{ flex-grow: 4; }
.grow-5{ flex-grow: 5; }

.shrink-0 { flex-shrink: 0;}
.shrink-1 { flex-shrink: 1;}
.shrink-2 { flex-shrink: 2;}
.shrink-3 { flex-shrink: 3;}

// Основная ось
.justify-start { justify-content: flex-start; }
.justify-end { justify-content: flex-end; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-around { justify-content: space-around; }

.j-start { justify-content: flex-start; }
.j-end { justify-content: flex-end; }
.j-center { justify-content: center; }
.j-between { justify-content: space-between; }
.j-around { justify-content: space-around; }

// Второстепенная ось
.items-stretch { align-items: stretch; }
.items-start { align-items: flex-start; }
.items-end { align-items: flex-end; }
.items-center { align-items: center; }
.items-baseline { align-items: baseline; }

// Выравнивание нескольких строчек или рядов
.content-stretch { align-content: stretch; }
.content-start { align-content: flex-start; }
.content-end { align-content: flex-end; }
.content-center { align-content: center; }
.content-between { align-content: space-between; }
.content-around { align-content: space-around; }

// Выравнивание отдельного блока по второстепенной оси
.self-start { align-self: flex-start; }
.self-end { align-self: flex-end; }
.self-center { align-self: center; }
.self-baseline { align-self: baseline; }
.self-stretch { align-self: stretch; }
.f-nowrap { flex-wrap: nowrap; }

// Сокращения
.stretch{
    align-items: stretch;
    align-content: stretch;
}
.center{
    align-items: center;
    align-content: center;
}
.end{
    align-items: flex-end;
    align-content: flex-end;
}
.start{
    align-items: flex-start;
    align-content: flex-start;
}
//
//
//

.fadeIn{
    visibility: hidden;
    opacity: 0;

}
.fadeOut{
    visibility: visible;
    opacity: 1;
}

.bg-white{
    background-color: #fff;
}

.ta-c { text-align: center; }
.ta-l { text-align: left; }
.ta-r { text-align: right; }

.t-d-none { text-decoration: none; }

@mixin outline($color: rgba(86, 61, 124, 0.2)) {
    outline: 1px solid $color;
}

.dotted-line-bot{
    border-bottom: 4px dotted $color-clouds;
}

.show-grid {
    div { @include outline; }
    img { @include outline($color-pumpkin); }
    a { @include outline($color-orange); }
    span { @include outline($color-clouds); }
}

.example{
    .cont {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    div {
        @include outline;
    }
    [class="col"],
    [class*="col-"] {
        padding-top: .75rem;
        padding-bottom: .75rem;
        background-color: rgba(86, 61, 124, 0.149); // border: 1px solid #563d7c33;
        @include outline(rgba(86, 61, 124, 0.2))
    }
}

.show-elements {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    ul {
        @include outline($color-orange);
    }
    p {
        @include outline($color-alizarin);
    }
    a {
        @include outline($color-orange);
    }
}

.thumb {
    section{
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #EBEBEB;
    }
    &__h1,&__h2,&__h3,&__h{
        font-family: $PTMono;
        color: #ddd;
    }
    &__img-270 {
        display: flex;
        width: 270;
        height: 270;
        background-color: $color-concrete;
        margin: 0 auto;
    }
    &__img-270x50 {
        display: block;
        width: 270px;
        height: 50px;
        background-color: $color-concrete;
        margin: 0 auto; // .flex_ccc
    }
    &__img-180 {
        display: flex;
        width: 180;
        height: 180;
        background-color: $color-concrete;
        margin: 0 auto;
    }
    &__img-120 {
        display: flex;
        width: 120;
        height: 120;
        background-color: $color-concrete;
        margin: 0 auto;
    }
    &__block {
        margin-top: 10;
        background-color: $color-clouds;
        padding: 5 10 5 10;
        // padding-left: 10px;
        // padding-right: 10px;
        outline: 1px solid #d3dcde;
    }
}

@mixin create-healpers-space {
    .p0 {padding:0px !important;}
    .pt1 {padding-top: 1 !important;}
    .pt2 {padding-top: 2 !important;}
    .pt3 {padding-top: 3 !important;}
    .pt4 {padding-top: 4 !important;}
    .pr95 {padding-right: 95px !important;}
    .pb70 {padding-bottom: 70px !important;}
    .pb100 {padding-bottom: 100px !important;}
    .mb-50 {margin-bottom: -50px !important;}

    $counter: 65;
    @while $counter>-1 {
        .pt#{$counter} { padding-top: #{$counter}px !important; }
        .pr#{$counter} { padding-right: #{$counter}px !important; }
        .pb#{$counter} { padding-bottom: #{$counter}px !important; }
        .pl#{$counter} { padding-left: #{$counter}px !important; }
        .mt#{$counter} { margin-top: #{$counter}px !important; }
        .mr#{$counter} { margin-right: #{$counter}px !important; }
        .mb#{$counter} { margin-bottom: #{$counter}px !important; }
        .ml#{$counter} { margin-left: #{$counter}px !important; }
        $counter: $counter - 5;
    }
}
@include create-healpers-space;

@mixin create-healpers-rem-space{
    .pb1rem{ padding-bottom: 1rem; }
    .pb2rem { padding-bottom: 2rem; }
    .pb05rem{ padding-bottom: .5rem; }

    $counter: 5;
    $half: $counter - 0.5;

    @while $counter > - 0 {
        .pb-#{$counter}-rem{ padding-bottom: #{$counter}rem; };
        .pt-#{$counter}-rem{ padding-top: #{$counter}rem; };
        .mb-#{$counter}-rem{ margin-bottom: #{$counter}rem; };
        .mt-#{$counter}-rem{ margin-top: #{$counter}rem; };
        $counter: $counter - 1;
        .pb-#{$counter}-5-rem{ padding-bottom: #{$half}rem; };
        .pt-#{$counter}-5-rem{ padding-top: #{$half}rem; };
        .mb-#{$counter}-5-rem{ margin-bottom: #{$half}rem; };
        .mt-#{$counter}-5-rem{ margin-top: #{$half}rem; };
        $half: $counter - 0.5;
    }
}
.pr-1-rem{ padding-right: 1rem; }
.pl-1-rem{ padding-left: 1rem; }
.pr-2-rem{ padding-right: 2rem; }
.pl-2-rem{ padding-left: 2rem; }
@include create-healpers-rem-space;
