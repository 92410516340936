.search-result{
    @extend .content_text;
    position: relative;

    dt, dd{
        margin-left: 2rem;
    }
    dt a{
        font-family: $RobotoMedium;
        color: $font-color-base;
    }
    &__num{
        position: absolute;
        left: 0;
    }
}