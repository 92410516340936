.breadcrumb{
    margin-top: 1em;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 1.5em;
    z-index: 100;
    position: relative;
    // padding-top: .5rem;
    padding-bottom: 1rem;
    list-style-type: none;

    &>div{
        margin-left: 15px;
    }
    &>div:first-child {
        margin-left: 0;
    }
}