h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  @extend %flex;
  margin-top: $headings-margin-top;
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
  max-width: 100%;
  // text-transform: uppercase;

}

h1, h2, .h1, .h2, h3, .h3{
  text-transform: none;
}

.landing{
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    @include media-breakpoint-up(md) {
      // padding-left: 1.5em;
    }
  }
  .gray-plate{
    .h1, .h2, .h3, h1, h2, h3{
      padding-left: 0;
    }
  }
}

h1, .h1 {
  font-size: $h1-font-size;
  font-family: $headings-font-family;
  margin-top: $h1-margin-top;
  margin-bottom: $h1-margin-bottom;
  text-transform: none;
}
h1, .h1 { font-size: $font-size-base * 2; }
h2, .h2 { font-size: $font-size-base * 1.666; }
h3, .h3 { font-size: $font-size-base * 1.222; }
h4, .h4 { font-size: $font-size-base * 1.111; }
h5, .h5 { font-size: $font-size-base * 1; }
h6, .h6 { font-size: $font-size-base * 1; }


@include media-breakpoint-up(sm){
  h1, .h1 { font-size: $h1-font-size; }
  h2, .h2 { font-size: $h2-font-size; }
  h3, .h3 { font-size: $h3-font-size; }
  h4, .h4 { font-size: $h4-font-size; }
  h5, .h5 { font-size: $h5-font-size; }
  h6, .h6 { font-size: $h6-font-size; }
}
// @include media-breakpoint-up(xl) {
//   h1, .h1 { font-size: $font-size-base * 2.125; }
//   h2, .h2 { font-size: $h2-font-size; }
// }

.items-name{
  font-family: $ff-h-bold;
  font-size: 1.22222rem;
  line-height: 1.3em;
}

.lead {
  font-size: $lead-font-size;
  font-family: $ff-italic;
  font-weight: 400;
  line-height: 1.4em;
}
.small{
  font-size: 0.8em;
  text-transform: none;
}

p{
  line-height: $paragraph-line-height;
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
  width: 100%;
  font-size: 1rem;
}

ul{
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
  padding-left: 1.7em;
  li{
    margin-bottom: .7em;
    line-height: 1.4em;
  }
  &.simple{
    margin-left: 0;
    padding-left: 0;
    li{
      list-style: none;
    }
  }
  &.pure{
    padding-left: 0;
    list-style: none;
  }
}

@mixin custom-markers($marker) {
	li{
		list-style: none;

		&::before{
			display: inline-block;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;

			font: var(--fa-font-solid);
			content: $marker;
			color: $color-second;
			line-height: inherit;

			margin-left: -1.5em;
			position: absolute;
		}
	}
}

dl{
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;

  dt{
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;

    font-family: $headings-font-family;
    color: $color-prime;
  }
  dd{
    margin-left: 0;
    padding-bottom: 1rem;
    ul{
      margin-bottom: 0;
    }
  }
}


.list-simple{
  &__group{
    padding-bottom: 1rem;
  }
  &__title{
    
  }
}

strong{
  font-family: $font-family-bold;
}
i{
  font-family: $font-family-italic;
}
.ff-medium{
  font-family: $ff-medium;
}

.ff-bold{
  font-family: $ff-bold;
}

.subheader{
  text-align: inherit;
  margin-top: -0.8em;
  margin-bottom: 1em;
  font-size: 1.125rem;
  font-family: $font-family-base;
}

.description{
  font-family: $ff-italic;
  padding-bottom: 1rem;
}
.table{
  .description{
    padding-bottom: 0;
  }
}

.initial-letter{
  padding-left: 1.5rem;  
}
.antiinitial-letter {
  padding-left: 0px !important;
}

.content_text{
  // flex-direction: column;
  line-height: $paragraph-line-height;
  font-size: 1rem;
  p{
    font-size: 1rem;
  }

  img{
    max-width: 100%;
    height: auto;
  }
}

.footnote{
  font-family: $ff-italic;
  line-height: $paragraph-line-height;

  padding: .6em 0 .6em 1.3em;
  margin-top: 1em;
  margin-bottom: 1em;
  border-left: 5px solid $color-gray-4;
}

.text_error{
  font-family: $ff-normal;
  color: $color-danger;
}

.color-second{
  color: $color-second!important;
}

.icon-prime-big{
  color: $color-second;
  font-size: 20px;
  margin-right: 0.5rem;
}

.fa-ruble-sign{
  font-size: 0.875rem;
  color: $color-gray-3;
}
.far{
  color: $color-second;
}

@mixin hidet-desc {
  height: 5em;
  overflow: hidden;
  &:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 4em;
      background: linear-gradient(to bottom, #F9F9F900, #fff 100%);
      pointer-events: none;
  }
}