.product-card {
    @include card;
    border: 2px solid $color-clouds;
    padding-top: 0.5rem;
    &__img{
        height: 12rem
    }
    &__anons{
        // @include hidet-desc;
        // height: 6em;
        // margin-bottom: 0.5rem;
    }
}
.product-card-short {
    @include card;
    &__img{
        height: 8rem;
        margin-bottom: $grid-gutter/3;
    }
    &__name{
        padding-top: 0.25rem;
        font-size: 1rem;
    }
}
.product-card-row {
    @include card;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
