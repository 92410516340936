%modal_pddings {
    padding-left: 3rem;
    padding-right: 3rem;
}

.modal-form{
    &.wrapper{
        border-radius: 10px;
        padding: 1.7rem 0;
    }
    &__title{
        h2{
            @extend %modal_pddings;
            // border-left: 8px solid $color-second;
        }
        p{
            @extend %modal_pddings;
        }
    }
    &__form{
        @extend %modal_pddings;
        form{
            padding-top: 0!important;
        }
    }
}