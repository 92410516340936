@mixin align($main, $second, $rows) {
    justify-content: $main;
    align-items: $second;
    align-content: $rows;
}
@mixin column{
    flex-direction: column;
    flex-basis: auto !important;
    >[class*="col"],
    >[class*="col-"] {
        flex-basis: auto;
    }
}
@mixin row{
    flex-direction: row;
    flex-basis: auto !important;
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint,
    $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            max-width: $container-max-width;
        }
    }
}

@mixin make-col($size, $columns: $grid-columns) {
    flex: 0 0 percentage($size / $columns);
    max-width: percentage($size / $columns);
    width: percentage($size / $columns); //last apdate
}

@mixin make-col-offset($size, $columns: $grid-columns) {
    $num: $size / $columns;
    margin-left: if($num==0, 0, percentage($num));
}