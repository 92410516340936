.category-card {
    @include card;

    @extend %hover-effect-shadow;
    &__wrapper{
    }

    &__img {
        height: 13em;
        margin-top: $grid-gutter/2;
        // padding-bottom: $grid-gutter/2;
    }

    &__name {
        padding-top: 0.5em;
        padding-bottom: 0.5em;

        font-family: $ff-h-bold;
        font-size: 1.125rem;
        text-align: center;
        span{
            text-align: center;
        }
    }
    @include hover-focus-active{
        border-color: transparentize($color-second, 0.5);
    }
}