// Фильтры

.filters {
    // margin-top: 1rem;
    // margin-left: 1.125em;
    margin-right: 0;
}

.filter {
    &__btn {
        margin-top: .5em;
    }
}

.m-menu{
    .accordion__item__header{
        color: $color-clouds;
        border-color:  $color-clouds;
        &[data-status='opened']{
            border-color:  $color-clouds;
        }
        @include hover-focus {
            border-color:  $color-clouds;
            cursor: pointer;

        }
    }
    input[type="checkbox"] + label{
        color: $color-clouds;
    }
}