
.super-hero-spaceing{
    padding-top: 6rem;
    padding-bottom: 7rem;
}

.hero-spaceing{
    padding-top: 4rem;
    padding-bottom: 5rem;
}

.large-spaceing{
    padding-top: 2.5rem;
    padding-bottom: 3rem;
}

.medium-spaceing{
    padding-top: 2em;
    padding-bottom: 2rem;
}

.small-spaceing{
    padding-top: 1rem;
    padding-bottom: 1rem;
}


.gray-section{
    background-color: $color-bg-2;
    border-top: 1px solid $color-gray-6;
}
.gray-plate {
    @extend .gray-section;
    @extend .large-spaceing;
    border-radius: 10px;
}
.white-plate{
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    padding: 0.5rem 0.5rem;
    border: 1px solid $color-clouds;
    @extend %plate-shadow;
    border-radius: 7px;
    @include media-breakpoint-up(sm){
        padding: 2rem 2.5rem;
    }
}

.bordered-section{
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
}
.bordered-plate{
    background-color: #fff;
    border: 2px solid $color-clouds;
}


.section-decor-line{
    width: 73.5%;
    height: 2px;
    background-color: $color-gray-4;
    
    position: absolute;
    top: 0;
    left: $grid-gutter/2;
}

.landing-separator{
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    background-color: #F9F9F9;
    height: 40px;
}
