.news{
    &__content{
        padding: 30px 0 30px 0;
        background-color: #fff;
        @include media-breakpoint-up(lg) {
            padding-left: 25px;
        }
    }
    &__article{
        text-align: justify;
        h1{
            text-align: left;
        }
    }
    &__item{
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: justify;
        h2{
            text-align: left;
        }
    }
}

.news-obj{
    h1{
        padding-left: 0;
    }
}